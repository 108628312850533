// Reset Css
@import url("reset.scss");

// Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "THICCCBOI";
  src: url("./Assets/fonts/THICCCBOI-Regular.woff2") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "THICCCBOI";
  src: url("./Assets/fonts/THICCCBOI-Medium.woff2") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "THICCCBOI";
  src: url("./Assets/fonts/THICCCBOI-SemiBold.woff2") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "THICCCBOI";
  src: url("./Assets/fonts/THICCCBOI-Bold.woff2") format("woff");
  font-weight: 700;
}

:root {
  --body-background: #ffffff;
  --body-color: #13111a;
  --link-color: #6923ff;
}

[data-theme="dark-theme"] {
  --body-background: #13111a;
  --body-color: #fff;
  --link-color: #6923ff;
}

body {
  font-family: "THICCCBOI";
  font-weight: 500;
}

.sign-social button,
p.account,
.form-subtitle,
.form-check a,
.form-check-label,
.form-title {
  color: var(--body-color);
}

//
.sing-area {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: grid;
  align-items: center;
  padding: 25px 0 0;
  overflow: hidden;
  background: var(--body-background);

  @media (max-width: 1399.98px) {
    padding-top: 80px;
  }

  @media (max-width: 575.98px) {
    padding-top: 30px;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::before {
    width: 333px;
    height: 490px;
  }
}

.sign-header {
  text-align: center;
}

.sign-form {
  width: 530px;
  margin: 45px auto;
  border-radius: 30px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 1px 3px 10px 0px rgba(117, 161, 219, 0.4);
  padding: 14px;
  position: relative;

  @media (max-width: 1399.98px) {
    margin: 100px auto 150px;
    width: 600px;
  }

  @media (max-width: 767.98px) {
    width: 90%;
    margin: 60px auto 80px;
  }

  @media (max-width: 575.98px) {
    padding: 0;
    background: none;
    border-radius: 20px;
  }

  .form-title {
    text-align: center;
    font-size: 40px;
    font-weight: 700;

    @media (max-width: 575.98px) {
      font-size: 30px;
    }
  }

  .form-subtitle {
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;

    @media (max-width: 575.98px) {
      font-size: 25px;
      line-height: 1.5;
    }
  }

  .form-floating {
    margin-top: 15px;

    .form-control {
      border-radius: 100px;
      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.08);
      box-shadow: 0px 2px 6px 0px rgba(39, 57, 147, 0.06),
        0px 0px 2px 0px rgba(39, 57, 147, 0.06) !important;
      border: 2px solid #fff;
    }

    input,
    label {
      color: #000;
      font-size: 18px;
      font-weight: 500;
      padding: 19px 24px;
    }
  }

  .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 35px 0 10px;

    input {
      border-radius: 0.25em;
      margin-top: -2px;
      margin-right: 11px;
      width: 20px;
      height: 20px;
      border: 2px solid #fff;
      background-color: "unset";

      &:checked {
        background-color: #6923ff;
      }
    }

    a,
    .form-check-label {
      font-family: "Epilogue";
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      @media (max-width: 575.98px) {
        font-size: 14px;
      }
    }

    a {
      border-bottom: 1px solid #fff;
    }
  }

  .form-floating > .form-control-plaintext:focus,
  .form-floating > .form-control-plaintext:not(:placeholder-shown),
  .form-floating > .form-control:focus,
  .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 12px;
  }

  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    transform: scale(0.85) translateY(-0.9rem) translateX(0.15rem);

    &::after {
      background: none;
    }
  }

  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(56px + calc(var(--bs-border-width) * 2));
    min-height: calc(56px + calc(var(--bs-border-width) * 2));
    line-height: 1.25;

    @media (max-width: 1399.98px) {
    }
  }

  .submit {
    width: 100%;
    height: 65px;
    text-align: center;
    border-radius: 100px;
    background: #13111a;
    box-shadow: 1px 4px 10px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.18px;
    border: 2px solid #fff;
    transition: all 0.3s;

    &:hover {
      background: #6923ff;
    }
  }

  p.account {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    padding: 5px 0 20px;

    @media (max-width: 575.98px) {
      font-size: 16px;
    }

    a {
      color: #6923ff;
    }
  }

  .sign-social {
    margin-top: 10px;

    display: grid;
    gap: 10px;

    button {
      border-radius: 48px;
      border: 1px solid #fff;
      box-shadow: 0px 2px 6px 0px rgba(39, 57, 147, 0.1);
      background: none;
      height: 60px;
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      @media (max-width: 1399.98px) {
        height: 60px;
      }
    }
  }
}

.sing-in-form {
  .submit {
    margin-top: 40px;
  }
}

.sign-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 120px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 767.98px) {
    display: grid;
    justify-content: center;
    height: auto;
    padding: 30px 0;
    text-align: center;
    gap: 10px;

    img {
      order: 1;
    }

    p {
      order: 1;
    }
  }

  .dark_mode {
    margin-top: -20px;
    margin-left: 10px;

    @media (max-width: 767.98px) {
      margin: -20px auto 0;
    }

    .dark_mode_label {
      width: 65px;
      height: 30px;
      position: relative;
      display: block;
      background: #ebebeb;
      border-radius: 200px;
      box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
        inset 0px -5px 15px rgba(255, 255, 255, 0.4);
      cursor: pointer;
      transition: 0.3s;
    }

    .dark_mode_label:after {
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      top: 3px;
      left: 3px;
      background: linear-gradient(180deg, #13111a, #13111a);
      border-radius: 180px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
      transition: 0.3s;
    }

    .dark_mode_input {
      width: 0;
      height: 0;
      visibility: hidden;
    }

    .dark_mode_input:checked + .dark_mode_label {
      background: #242424;

      &:after {
        left: 62px;
        transform: translateX(-100%);
        background: linear-gradient(180deg, #6923ff, #6923ff);
      }
    }

    .dark_mode_label:active:after {
      width: 30px;
    }

    .dark_mode_label svg {
      position: absolute;
      width: 20px;
      top: 5px;
      z-index: 100;

      &.sun {
        left: 5px;
        fill: #fff;
        transition: 0.3s;
      }

      &.moon {
        left: 40px;
        fill: #7e7e7e;
        transition: 0.3s;
      }
    }

    .dark_mode_input:checked + .dark_mode_label {
      svg.sun {
        fill: #7e7e7e;
      }

      svg.moon {
        fill: #fff;
      }
    }
  }
}

[data-theme="light-theme"] {
  .sing-area {
    &::before {
      background-image: url("./Assets/images/light-form-bg2.svg");
    }

    &::after {
      background-image: url("./Assets/images/light-form-bg.svg");
      width: 978px;
      height: 1085px;
      margin: 0 auto;
      right: 0;
      top: 50%;
      transform: translate(-4%, -45%);
    }

    .sign-form {
      .sign-box {
        border-radius: 30px;
        border: 1px solid #fff;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 1px 3px 10px 0px rgba(117, 161, 219, 0.4);
        padding: 27px 40px 36px;

        @media (max-width: 767.98px) {
        }

        @media (max-width: 575.98px) {
          padding: 30px 25px;
          border-radius: 20px;
        }
      }
    }
  }
}

[data-theme="dark-theme"] {
  .sing-area {
    &::before {
      background-image: url("./Assets/images/dark2.svg");
    }

    &::after {
      background-image: url("./Assets/images/dark.svg");
      width: 980px;
      height: 900px;
      margin: 0 auto;
      right: 0;
      top: 37%;
      transform: translate(8%, -39%);
    }

    .sign-social button,
    .form-check-label,
    .form-check a {
      color: #fff;
    }

    p.account {
      color: #464d6a;
    }

    .form-title,
    .form-subtitle {
      background: var(
        --Text-Grade-2,
        radial-gradient(
          70.71% 70.71% at 50% 50%,
          #fff 0%,
          rgba(255, 255, 255, 0) 100%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .sign-form {
      border-radius: 40px;
      border: 1px solid #4c4a51;
      background: rgba(32, 32, 45, 0.4);
      box-shadow: 0px 2px 12px 0px rgba(39, 57, 147, 0.08);
      padding: 50px 55px;

      @media (max-width: 767.98px) {
        padding: 40px 45px;
      }

      @media (max-width: 575.98px) {
        padding: 30px;
        border-radius: 20px;
      }

      .form-floating {
        .form-control {
          border-color: #4c4a51;
          background: white;
        }

        input,
        label {
          color: #464d6a;
        }

        input {
          color: #d9d9d9;
        }
      }

      .form-check {
        input {
          border: 2px solid #6923ff;

          &:checked {
            background-color: #6923ff;
          }
        }
      }

      .submit {
        border: 0;
        background: linear-gradient(
          90deg,
          #4059de 0%,
          #6f1e91 57.99%,
          #6208a9 100%
        );
        box-shadow: 0px 3px 7px 0px rgba(19, 17, 26, 0.14);
        position: relative;
        z-index: 1;
        transition: all 0.3s;

        &:hover {
          &::after {
            background: #6923ff;
          }
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 99%;
          height: 95%;
          background: #13111a;
          z-index: -1;
          border-radius: 100px;
          transform: translate(-50%, -50%);
          transition: all 0.3s;
        }
      }

      .sign-social {
        button {
          border: 1px solid #423d52;
          background: #13111a;
          box-shadow: 0px 2px 6px 0px rgba(39, 57, 147, 0.1);
        }
      }
    }

    .sign-footer {
      background: rgba(72, 109, 251, 0.05);

      p {
        color: #d9d9d9;
      }
    }
  }
}
